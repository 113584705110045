.cl-input {
    width: calc(100% - 20px);
    outline: none;
    background-color: transparent;
    border: none;
    color: var(--menu-text);
    padding-top: 2px;
    padding-bottom: 3px;
    font-size: var(--cl-input-font-size);
    font-family: var(--cl-input-font-familiy);
}


.cl-text-info-cover {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
    /* padding-left: 5px; */
    border-bottom: 1px solid transparent;
}

.cl-text-bottom-line {
    border-bottom: 1px solid var(--cl-input-bottom-border);
}

.cl-text-info-cover:after {
    content: "";
    position: absolute;
    width: 0px;
    height: 1px;
    background: #6278e0;
    transition: all 0.4s;
    bottom: 0;
    left: 50%;
}

.cl-text-info-cover:hover:after {
    width: 100%;
    height: 1px;
    left: 0;
}

.cl-text-info-cover-focus {
    border-bottom: 1px solid red;
    border-color: red;
    -webkit-transition: all .8s ease-out;
    -moz-transition: all .8s ease-out;
    -ms-transition: all .8s ease-out;
    -o-transition: all .8s ease-out;
    transition: all .8s ease-out
}



.awsome_input_border {
    display: inline-block;
    width: 0px;
    height: 2px;
    background: #FEC938;
    position: relative;
    top: -5px;
    -webkit-transition: all ease-in-out .15s;
    -o-transition: all ease-in-out .15s;
    transition: all ease-in-out .15s;
}

.cl-text-info {
    font-size: 14px;
    font-family: 'Dosis', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 4px;
    background-color: palegreen;
    color: black;
    display: block;
    white-space: nowrap;
    border-radius: 3px;
    margin-right: 5px;
}

.cl-text-pointer {
    /* font-size: 14px; */
    font-family: 'Dosis', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 4px;
    /* background-color: palegreen; */
    color: #b591d6;
    display: block;
    white-space: nowrap;
    border-radius: 3px;
    margin-right: 5px;
}

button,
input {
    overflow: visible;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}

.cl-error {
    border-inline-start: 1px solid red !important;
}

.cl-sub-cover {
    display: flex;
    align-items: center;
    width: 100%;
}

.cl-sub-header {
    margin-left: 10px;
    background-color: var(--me);
    border-radius: 4px 0px 0px 4px;
    width: 40px;
    font-size: 12px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 5px;
    padding-right: 5px;
    width: 75px;
}

.cl-label {
    margin-bottom: 5px;
    font-size: 12px;
    color: var(--cl-label-color);
}

.cl-textarea-input {
    width: calc(100% - 20px);
    outline: none;
    background-color: var(--menu-background);
    border: none;
    border-radius: 4px;
    color: var(--menu-text);
    width: 100%;
    font-size: var(--cl-input-font-size);
    font-family: var(--cl-input-font-familiy);
}

.cl-close-icon {
    color: var(--menu-text);
    /* padding-right: 10px; */
}

.cl-close-icon:hover {
    color: var(--menu-selected);
}



.cl-down-icon {
    color: var(--menu-text);
    padding-right: 10px;
    /* height: 30px;
    line-height: 30px; */
    border-radius: 5px;
}

.cl-down-icon:hover {
    color: red;
}

.cl-line {
    margin-top: 10px;
}

.search-input {
    width: calc(100% - 20px);
    outline: none;
    background-color: transparent;
    border: none;
    color: var(--menu-text);
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: var(--cl-input-font-size);
    font-family: var(--cl-input-font-familiy);
    cursor: pointer;
}

.search-cover {
    color: var(--menu-text);
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: 'montserrat';
    cursor: pointer;
}




.search-item {
    color: var(--menu-text);
    width: 99%;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden;
    padding: 4px;
    cursor: pointer;
}

.search-item:hover {
    background-color: var(--menu-selected);
    color: var(--menu-selected-text);
    border-radius: 3px;
}

.search-list {
   /*  border-radius: 5px;
    position: absolute;
    overflow: scroll;
    z-index: 1500;
    max-width: 400px;
    padding: 10px;
    background-color: var(--menu-background);
    max-height: 400px;
    box-shadow: var(--cl-search-list-shadow);
    margin-top: 5px;
    min-width: 300px; */


    border-radius: 5px;
    position: absolute;
    overflow: scroll;
    z-index: 1500;
    max-width: 400px;
    padding: 10px;
    background-color: var(--menu-background);
    max-height: 400px;
    box-shadow: var(--cl-search-list-shadow);
    margin-top: 5px;
    min-width: 150px;
    margin-top: 153px;
    width: auto;
}

.item-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.selected {
    background-color: var(--menu-selected);
    color: var(--menu-selected-text);
    border-radius: 3px;
}

.loading-image {
    width: 30px;
    height: 30px;
}

.cl-upper {
    text-transform: uppercase;
}

.cl-lower {
    text-transform: lowercase;
}

.cl-capitalize {
    text-transform: lowercase;
}

.cl-capitalize::first-line {
    text-transform: capitalize;
}

.cl-ok-button {
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: none;
    background-color: var(--cl-ok-button-background);
    color: var(--cl-ok-button-text);
    min-width: 110px;
}

.cl-ok-button:hover {
    background-color: var(--cl-ok-button-hover-background);
}

.cl-ok-button:disabled {
    opacity: 0.2;
}

.cl-cancel-button {
    padding: 10px;
    border-radius: 4px;
    margin: 10px;
    border: none;
    background-color: var(--cl-cancel-button-background);
    color: var(--cl-cancel-button-text);
    min-width: 110px;
}

.cl-cancel-button:hover {
    background-color: var(--cl-cancel-button-hover-background);
}

.cl-cancel-button:disabled {
    opacity: 0.2;
}

.cl-highlight {
    color: greenyellow;
} 

.cl-disabled {
    color: red;
}