.cloud-form {
    max-width: 1000px;
    font-family: "montserrat";
    font-size: 16px;
    margin: auto;
    padding: 10px;
    box-shadow: 0px 0px 8px 3px #0e1642;
    border-radius: 4px;
    background-color: var(--workspace-background);
    color: var(--menu-text);
    height: Calc(100vh - 100px);
    overflow: auto;
    box-sizing: border-box;
}

.cloud-header {
    font-size: 16px;
    text-align: center;
    padding: 5px;
    border-bottom: 1px solid var(--menu-background);
    margin-bottom: 10px;
}

.cloud-group {
    font-size: 16px;
    margin-bottom: 5px;
    margin-top: 25px;
    color: var(--cl-input-group-text);
    border-bottom: 1px solid;
    padding: 3px;
}


.cloud-sub-group {
    font-size: 14px;
    margin-bottom: 3px;
    margin-top: 15px;
    color: var(--cl-input-sub-group-text);
    border-bottom: 1px solid;
    padding: 3px;
}
.cloud-field {
    display: flex;
    flex-direction: var(--field-direction);
    justify-content: space-between;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
}

.jw-modal {
    /* modal container fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* z-index must be higher than .jw-modal-background */
    z-index: 1000;
    
    /* enables scrolling for tall modals */
    overflow: auto;

    
}


.jw-modal-body {
    padding: 20px;
    /* background: #fff; */

    /* margin exposes part of the modal background */
    margin: 40px;
}

/* MODAL STYLES
-------------------------------*/
jw-modal {
    /* modals are hidden by default */
    display: none;
    

}


.jw-modal-background {
    /* modal background fixed across whole screen */
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* semi-transparent black  */
    background-color: #000;
    opacity: 0.75;
    
    /* z-index must be below .jw-modal and above everything else  */
    z-index: 900;
}

body.jw-modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open */
    overflow: hidden;
}