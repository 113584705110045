/* You can add global styles to this file, and also import other style files */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

body {
    background-color: rgb(236, 239, 241);
}

html {
    position: fixed;
    height: 100%;
    overflow: hidden;
}

body {
    width: 100vw;
    /* height: 100vh; */
    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.img-160-95 {
    width: 160px;
    height: 95px;
    border: 1px solid rgb(143, 143, 143);
    -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.67);
    -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.67);
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.67);
}

.edit-panel-footer {
    position: fixed;
    bottom: 10px;
    height: 50px;
    max-width: 600px;
    padding: 10px;
    /* margin-left: -25px; */
    width: 85%
}

.sub-header {
    position: relative;
    height: 40px;
    background: var(--header-background);
    color: var(--header-text);
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 100;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .17);
    border-radius: 8px;
    margin-top: 10px;
    margin-left: 30px;
    margin-right: 30px;
    font-family: var(--other-font);
    font-size: 12px;
    text-align: center;
}

.card {
    width: 800px;
    min-height: 300px;
    border-radius: 8px;
    border: 1px solid var(--workspace-background);
    margin: auto;
    background-color: var(--workspace-background);
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, .17);
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    color: var(--header-text);
}

.card-line {
    display: flex;
    flex-direction: row;
    color: black;
    padding: 5px;
}

.card-title {
    color: var(--menu-text);
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    width: 200px;
    font-family: 'Montserrat', sans-serif;
    padding: 5px;
}

.card-value {
    color: rgba(0, 0, 0, 0.54);
    display: inline-block;
    font-size: 13px;
    line-height: 20px;
    width: 200px;
    font-family: 'Montserrat', sans-serif;
    padding: 5px;
}

.section-title {
    width: 800px;
    min-height: 50px;
    color: black;
    font-family: 'Montserrat', sans-serif;
    color: var(--menu-text);
    font-weight: 700;
    line-height: 50px;
    align-items: center;
    margin: auto;
}

.card-info {
    width: 800px;
    /* min-height: 50px; */
    color: black;
    font-family: 'Montserrat', sans-serif;
    color: var(--menu-text);
    font-weight: bold;
    /* line-height: 50px; */
    align-items: center;
    margin: auto;
    font-size: 14px;
    background-color: var(--menu-color);
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

:root {
    --primary-light: #819ca9;
    --primary: #546e7a;
    --primary-dark: #29434e;
    --secondary: #d81b60;
    --secondary-light: #ff5c8d;
    --secondary-dark: #a00037;
    --primary-text: #ffffff;
    --secondary-text: #ffffff;
    /* --primary-light: #9162e4;
	--primary: #5e35b1;
	--primary-dark: #280680;
	--secondary: #ea80fc;
	--secondary-light: #ffb2ff;
	--secondary-dark: #b64fc8;
	--primary-text: #ffffff;
	--secondary-text: #000000; */
    --product-font: 'Cabin', sans-serif;
    --product-group-font: 'Cabin', sans-serif;
    --modifier-font: 'Cabin', sans-serif;
    --folio-font: 'Montserrat', sans-serif;
    --other-font: 'Cabin', sans-serif;
    /* button menu variables */
    --button-width: 80px;
    --button-height: 70px;
    --button-menu-width: 80px;
    --button-menu-height: 70px;
    --button-menu-color: #0F1642;
    --button-menu-text: white;
    --button-menu-disabled-color: #0F1642;
    --button-menu-disabled-text: white;
    --button-menu-hover-color: rgb(136, 123, 253);
    --button-menu-selected-color: #9E94FF;
    --button-menu-font: 'Cabin', sans-serif;
    /* menü items */
    --product-button-width: 160px;
    --product-button-height: 170px;
    --product-font-size: 14px;
    --product-price-font-size: 14px;
    --group-font-size: 14px;
    --group-button-width: 160px;
    --group-button-height: 170px;
    /* Dark Mode */
    --menu-background: #0F1642;
    --menu-selected: #9E94FF;
    --menu-selected-text: black;
    --menu-sub-selected: #978CFC;
    --menu-text: white;
    --menu-logo-text: #7E72F2;
    --workspace-background: #2B3253;
    --workspace-text: white;
    --header-background: #0F1642;
    --header-text: white;
    /* Folio */
    --folio-background: #0F1642;
    --folio-text: white;
    --folio-row-selected: #9E94FF;
    --folio-row-selected-text: rgb(12, 10, 29);
    --folio-row-hover: rgb(47, 59, 126);
    --folio-price-line-throuh: red;
    --folio-row-gift: red;
    --folio-row-note: red;
    --folio-total-row: #20b2aa;
    --folio-discount-row: #cebd0f;
    --folio-payment-row: #9acd32;
    --folio-balance-row: white;
    --folio-change-row: #20b2aa;
    --folio-row-cover-border: #1919d8;
    /* PopUp */
    --popup-background: black;
    --popup-text: white;
    /* Menu */
    --product-background: #0F1642;
    --product-group-background: #0F1642;
    --product-text: white;
    --product-group-text: white;

    /*cl components */
    --focused-input : #091a82;;
    --cl-input-font-familiy: 'Montserrat';
    --cl-input-font-size: 14px;
    --cl-toolbar-background: #0d1231;
    --cl-toolbar-text: white;
    --cl-label-color: #c1c39b;
    --cl-input-bottom-border: #0F1642; 
    --cl-input-sub-group-text: #7a9db3; 
    --cl-input-group-text: #99c6d8;

    --cl-ok-button-background: #066349;
    --cl-ok-button-text: white;

    --cl-ok-button-hover-background: #099870;
    --cl-ok-button-hover-text: white;

    --cl-cancel-button-background: #630631;
    --cl-cancel-button-text: white;

    --cl-cancel-button-hover-background: #940849;
    --cl-cancel-button-hover-text: white;

    --cl-grid-toolbar-background:#0d1231;
    --cl-grid-toolbar-text:white;

    --cl-grid-background:#2B3253;
    --cl-grid-text:white;

    --cl-search-list-shadow: 0px 0px 8px 3px #0e1642;

}

@media print {
    app-root > * { display: none; }
    app-root app-print-layout { display: block; }
  }

@media (max-device-width: 768px) {
     :root {
        --button-width: 70px;
        --button-height: 60px;
        --button-menu-width: 70px;
        --button-menu-height: 60px;
        --product-button-width: 115px;
        --product-button-height: 125px;
        --group-button-width: 115px;
        --group-button-height: 125px;
        --product-font-size: 10px;
        --product-price-font-size: 12px;
        --group-font-size: 10px;
    }
}

input:-webkit-autofill {
    background-color: transparent !important;
}

.kerzz-label {
    display: flex;
    flex-direction: column;
}

.kerzz-save {
    background-color: var(--primary-light);
    color: var(--primary-text);
    height: 50px;
    font-family: var(--other-font);
    border: 1px solid var(--primary);
}

.kerzz-cancel {
    background-color: var(--primary-dark);
    color: var(--primary-text);
}

.kerzz-other {
    background-color: var(--secondary-light);
    color: var(--secondary-text);
}

.menu-button {
    width: 70px;
    height: 60px;
    background-color: var(--button-menu-color);
    color: var(--button-menu-text);
    border-radius: 3px;
    text-align: center;
    line-height: 25px;
    margin: 5px;
    font-family: var(--product-group-font);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.menu-button:hover {
    background-color: var(--button-menu-hover-color);
}

.menu-button-selected {
    background-color: var(--button-menu-selected-color);
}

::-webkit-scrollbar {
    display: none;
}

.wobble {
    -webkit-animation-name: wobble;
    animation-name: wobble;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none;
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes wobble {
    0% {
        -webkit-transform: none;
        transform: none;
    }
    15% {
        -webkit-transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
        transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg);
    }
    30% {
        -webkit-transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
        transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg);
    }
    45% {
        -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
        transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg);
    }
    60% {
        -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
        transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg);
    }
    75% {
        -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
        transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    }
    100% {
        -webkit-transform: none;
        transform: none;
    }
}

.rubberBand {
    -webkit-animation-name: rubberBand;
    animation-name: rubberBand;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes rubberBand {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    30% {
        -webkit-transform: scale3d(1.25, 0.75, 1);
        transform: scale3d(1.25, 0.75, 1);
    }
    40% {
        -webkit-transform: scale3d(0.75, 1.25, 1);
        transform: scale3d(0.75, 1.25, 1);
    }
    50% {
        -webkit-transform: scale3d(1.15, 0.85, 1);
        transform: scale3d(1.15, 0.85, 1);
    }
    65% {
        -webkit-transform: scale3d(.95, 1.05, 1);
        transform: scale3d(.95, 1.05, 1);
    }
    75% {
        -webkit-transform: scale3d(1.05, .95, 1);
        transform: scale3d(1.05, .95, 1);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.shake {
    -webkit-animation-name: shake;
    animation-name: shake;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

@keyframes shake {
    0%,
    100% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        -webkit-transform: translate3d(-10px, 0, 0);
        transform: translate3d(-10px, 0, 0);
    }
    20%,
    40%,
    60%,
    80% {
        -webkit-transform: translate3d(10px, 0, 0);
        transform: translate3d(10px, 0, 0);
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@-webkit-keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}



.top-modal {
    position: relative;
    z-index: 20001;
}

.tab-bar-header {
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
}

.tab-bar-item {
    padding: 10px;
    flex: 1;
    text-align: center;
    cursor: pointer;
    transition: all;
}
.tab-bar-item.active-tab {
    background-color: green;
}


.error-content {
    background-color: red;
    color: white;
    box-sizing: border-box;
    min-height: 200px;
}

.all-center {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    text-align: center !important;
}